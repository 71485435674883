// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-inquiry-js": () => import("./../../../src/pages/careers/inquiry.js" /* webpackChunkName: "component---src-pages-careers-inquiry-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-eduplus-lms-js": () => import("./../../../src/pages/products/eduplus-lms.js" /* webpackChunkName: "component---src-pages-products-eduplus-lms-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-product-template-js": () => import("./../../../src/pages/products/product-template.js" /* webpackChunkName: "component---src-pages-products-product-template-js" */),
  "component---src-pages-products-yela-erp-js": () => import("./../../../src/pages/products/yela-erp.js" /* webpackChunkName: "component---src-pages-products-yela-erp-js" */),
  "component---src-pages-services-business-concept-development-js": () => import("./../../../src/pages/services/business-concept-development.js" /* webpackChunkName: "component---src-pages-services-business-concept-development-js" */),
  "component---src-pages-services-cyber-security-js": () => import("./../../../src/pages/services/cyber-security.js" /* webpackChunkName: "component---src-pages-services-cyber-security-js" */),
  "component---src-pages-services-it-consultancy-js": () => import("./../../../src/pages/services/it-consultancy.js" /* webpackChunkName: "component---src-pages-services-it-consultancy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-managed-cloud-service-js": () => import("./../../../src/pages/services/managed-cloud-service.js" /* webpackChunkName: "component---src-pages-services-managed-cloud-service-js" */),
  "component---src-pages-services-managed-it-service-js": () => import("./../../../src/pages/services/managed-it-service.js" /* webpackChunkName: "component---src-pages-services-managed-it-service-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

